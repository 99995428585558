import Utils from "@/vue/infrastructure/utils/helpers";
import { Component, Vue } from "vue-property-decorator";
import DfStore from "@/vue/domain/store/df-store";
import DfBrand from "@/vue/domain/brand/df-brand";
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfStoreSelectionComponent = () =>
  Utils.externalComponent2("df-store-selection");
const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfStoreCard: DfStoreCardComponent,
    DfStoreSelection: DfStoreSelectionComponent,
  },
})
export default class DfPageStoreChangeComponent extends Vue {
  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }
}
